<script>
import Layout from "@/router/layouts/franchise";
import { api } from "@/state/services";
import { required } from "vuelidate/lib/validators";
import { VMoney } from "v-money";
import Password from "@/components/widgets/input-password";

export default {
  locales: {
    pt: {
      Transfer: "Transferir",
      "The wallet is invalid.": "A carteira é inválida.",
      "The username was not found.": "O usuário não foi encontrado.",
      "Enter a transfer amount.": "Digite o valor da transferência.",
      "The minimum transfer amount is CC 1,00":
        "O valor mínimo para transferência é de CC 1,00.",
      "You do not have enough balance to transfer.":
        "Você não possui saldo suficiente para essa transferência.",
      "Your security password is incorrect.":
        "Sua senha de segurança está incorreta.",
      "Your transfer was successful.":
        "Sua transferência foi realizada com sucesso.",
      "From wallet": "Da carteira",
      "From wallet is required.": "A carteira é obrigatória.",
      "Wallet balance": "Saldo da carteira",
      "To wallet": "Para carteira",
      "To wallet is required.": "A carteira é obrigatória.",
      "Username to": "Para o usuário",
      "Username is required.": "O usuário é obrigatório",
      "Transfer amount": "Valor da transferência",
      "Amount is required.": "O valor é obrigatório",
      "Security password": "Senha de segurança",
    },
    es: {
      Transfer: "Transferir",
      "The wallet is invalid.": "A carteira é inválida.",
      "The username was not found.": "O usuário não foi encontrado.",
      "Enter a transfer amount.": "Digite o valor da transferência.",
      "The minimum transfer amount is CC 1,00":
        "O valor mínimo para transferência é de CC 1,00.",
      "You do not have enough balance to transfer.":
        "Você não possui saldo suficiente para essa transferência.",
      "Your security password is incorrect.":
        "Sua senha de segurança está incorreta.",
      "Your transfer was successful.":
        "Su transferencia se ha realizado correctamente.",
      "From wallet": "De la cartera",
      "From wallet is required.": "La cartera se requiere.",
      "Wallet balance": "Saldo de la cartera",
      "To wallet": "Para la cartera",
      "To wallet is required.": "La cartera se requiere.",
      "Username to": "Para o usuario",
      "Username is required.": "Nombre de usuario es requerido.",
      "Transfer amount": "Valor da transferencia",
      "Amount is required.": "El valor se requiere.",
      "Security password": "Contraseña de seguridad",
    },
  },
  components: { Layout, Password },
  data() {
    return {
      loading: false,
      success: false,

      balance: "0.00",
      username: "",
      value: "",
      password: "",

      money: {
        decimal: ",",
        thousands: ".",
        prefix: "CC ",
        suffix: "",
        precision: 2,
      },

      alert: {
        username: { type: "", message: "" },
        transfer: { type: "", message: "" },
      },
    };
  },
  directives: { money: VMoney },
  validations: {
    username: { required },
    value: { required },
    password: { required },
  },
  methods: {
    getBalance() {
      this.balance = "0.00";

      api.get("franchise/wallet/balance").then((response) => {
        if (response.data.status == "success") {
          this.balance = this.$options.filters.currency(response.data.balance);
        }
      });
    },
    getUsername() {
      if (this.username) {
        api
          .post("franchise/wallet/transfer/username", {
            username: this.username,
          })
          .then((response) => {
            if (response.data.status == "success") {
              this.alert.username.type = "text-success";
              this.alert.username.message = response.data.user.name;
            } else {
              this.alert.username.type = "text-danger";
              this.alert.username.message = response.data.message;
            }
          });
      } else {
        this.alert.username.type = "";
        this.alert.username.message = "";
      }
    },
    transterSubmit() {
      this.loading = true;
      this.$v.$touch();

      if (this.value) {
        api
          .post("franchise/wallet/transfer", {
            username: this.username,
            value: this.value,
            password: this.password,
          })
          .then((response) => {
            if (response.data.status == "success") {
              this.success = true;
              this.username = "";
              this.value = "0.00";
              this.password = "";
              this.$v.$reset();

              this.alert.transfer.type = "alert-success";
              this.alert.transfer.message = response.data.message;
            } else {
              this.password = "";
              this.$v.$reset();

              this.alert.transfer.type = "alert-danger";
              this.alert.transfer.message = response.data.message;
            }

            this.loading = false;
          });
      }
    },
  },
  mounted() {
    this.getBalance();
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">Carteira › {{ t("Transfer") }}</h4>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-7">
        <div class="card">
          <div class="card-body p-4">
            <div v-if="success" class="text-center-sm">
              {{ t("Your transfer was successful.") }}
            </div>
            <b-form v-else class="p-3" @submit.prevent="transterSubmit">
              <div
                v-if="alert.transfer.message"
                :class="'alert ' + alert.transfer.type"
              >
                {{ t(alert.transfer.message) }}
              </div>
              <b-form-group
                id="balance"
                :label="t('Wallet balance') + ' (HD)'"
                label-for="balance"
              >
                <b-form-input
                  id="balance"
                  v-model="balance"
                  type="text"
                  disabled
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="username"
                :label="t('Username to') + ' (Afiliado)'"
                label-for="username"
              >
                <b-form-input
                  id="username"
                  v-model="username"
                  type="text"
                  :class="{ 'is-invalid': $v.username.$error }"
                  v-mask="'XXXXXXXXXXXXXXXXXXXXXXXXXXXXX'"
                  v-on:blur="getUsername()"
                  autocomplete="off"
                ></b-form-input>
                <div v-if="$v.username.$error" class="invalid-feedback">
                  <span v-if="!$v.username.required">{{
                    t("Username is required.")
                  }}</span>
                </div>
                <div
                  v-if="alert.username.message"
                  :class="'mt-1 p-2 px-3 ' + alert.username.type"
                >
                  {{ t(alert.username.message) }}
                </div>
              </b-form-group>
              <b-form-group
                id="value"
                :label="t('Transfer amount')"
                label-for="value"
              >
                <b-form-input
                  id="value"
                  v-model="value"
                  v-money="money"
                  type="text"
                  inputmode="decimal"
                  :class="{ 'is-invalid': $v.value.$error }"
                ></b-form-input>
                <div v-if="$v.value.$error" class="invalid-feedback">
                  <span v-if="!$v.value.required">{{
                    t("Amount is required.")
                  }}</span>
                </div>
              </b-form-group>
              <b-form-group
                id="password"
                :label="t('Security password')"
                label-for="password"
              >
                <Password v-model="password" :v="$v.password"></Password>
              </b-form-group>
              <div class="mt-4">
                <b-button
                  :disabled="
                    loading == true ||
                    !this.username ||
                    this.value === '0.00' ||
                    !this.password
                  "
                  type="submit"
                  variant="default"
                >
                  {{ t("Transfer") }}
                  <b-spinner
                    v-if="loading"
                    small
                    class="ml-2 align-middle"
                    variant="white"
                    role="status"
                  ></b-spinner>
                </b-button>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
